import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import MessagesForm from '../forms/Messages'
import BillingMessages from '../../components/Tables/BillingMessages'

export default function ClaimFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Review your Recent Private and SecureForm Messages'}>
        <BillingMessages />
        <MessagesForm />
      </DashboardSection>
    </DashboardBox>
  )
}
