import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import FeedbackIcon from '@mui/icons-material/Feedback'
import {Icon} from '../../../../../app/components/Icons/Icon'
import {NavLink, useNavigate} from 'react-router-dom'
import React from 'react'
// import AccountCircle from '@mui/icons-material/AccountCircle'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export function MenuInner() {
  const intl = useIntl()
  // Get both types of unread message
  const privateUnread = useSelector((state: any) => state.auth.BillingUnread)
  const secureUnread = useSelector((state: any) => state.auth.Unread)
  // Now add them to together if they aren't null - love nulls
  const unread = privateUnread && secureUnread ? Number(privateUnread) + Number(secureUnread) : privateUnread || secureUnread || 0
  // console.log('unread', unread)
  // console.log('privateUnread', privateUnread)
  // console.log('secureUnread', secureUnread)
  //
  const FSBP = useSelector((state: any) => state.member.FSBP)
  return (
    <div className='flex items-center justify-center gap-5'>
      {FSBP ? (
        <NavLink to='/submit-claim'>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            disableElevation
            // sx={{ textTransform: 'none', borderRadius: 20 }}
          >
            New Claim
          </Button>
        </NavLink>
      ) : null}
      <a href='https://www.afspa.org/secure-form-member-portal-support/' target='_blank' rel='noopener noreferrer'>
        <Button variant='contained' startIcon={<FeedbackIcon />} disableElevation>
          Feedback
        </Button>
      </a>
      <NavLink to='messages'>
        <IconButton aria-label='show new messages' color='inherit'>
          <Badge badgeContent={unread} color='error'>
            <Icon name='email' />
          </Badge>
        </IconButton>
      </NavLink>
      <AccountMenu />
    </div>
  )
}

export default function AccountMenu() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const optionSelect = (page: string) => {
    setAnchorEl(null)
    navigate(page)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleMenu} color='inherit'>
        <Icon name='person' />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{mt: '40px'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => optionSelect('/profileform')}>View profile</MenuItem>
        <MenuItem onClick={() => optionSelect('/passwordupdateform')}>Update Password</MenuItem>
        <MenuItem onClick={() => optionSelect('/logout')}>Log out</MenuItem>
      </Menu>
    </div>
  )
}
